const envProd = {
  host: "",
  checkAuth: "https://app-cv-oauth-dev.azurewebsites.net/checkAuthorization",
  redirectHome: "https://app-cv-oauth-dev.azurewebsites.net/auth/azureadoauth2",
  redirectLogin:
    "https://app-cv-oauth-dev.azurewebsites.net/auth/azureadoauth2",
  redirectTerms: "https://app-campusvirtual-dev.azurewebsites.net/public-terms",
};
const envDev = {
  host: "http://localhost:3003",
  checkAuth: "http://localhost:3003/checkAuthorization",
  redirectHome: "http://localhost:3031/auth/azureadoauth2",
  redirectLogin: "http://localhost:3031/auth/azureadoauth2",
  redirectTerms:
    "https://app-campusvirtual-dev.azurewebsites.net/public-terms/",
  contentAssets:
    "https://campusvirtual.azureedge.net/public/assets/images/uteg/",
};

const envNode = {
  host: "",
  checkAuth: process.env.REACT_APP_CHECK_AUTH,
  redirectHome: process.env.REACT_APP_REDIRECT_HOME,
  redirectLogin: process.env.REACT_APP_REDIRECT_LOGIN,
  redirectTerms: process.env.REACT_APP_REDIRECT_TERMS,
  contentAssets: process.env.REACT_APP_CONTENT_ASSETS,
};
const env = {
  ...envNode,
};

export default env;

import React, { useEffect } from "react";
import "@lottus23/lottus-elements-uteg/elements";

export const Feedback = ({ data, onRight, text, html }) => {
  const feedbackRef = React.createRef();

  useEffect(() => {
    feedbackRef.current.data = {
      left: data.left || {},
      right: data.right || {},
      type: data.type || "",
      isTextEvent: data.isTextEvent,
      textEvent: data.textEvent || "",
    };
  }, [data, text]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    feedbackRef.current.addEventListener("onRight", onRight);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <lottus-feedback ref={feedbackRef}>
        <div content="">{text || html()}</div>
      </lottus-feedback>
    </>
  );
};

import React, { useEffect } from "react";
import "@lottus23/lottus-elements-uteg/elements";

export const Button = React.memo(({ data, onClick }) => {
  const buttonRef = React.createRef();

  useEffect(() => {
    buttonRef.current.data = {
      type: data.type || "primary",
      title: data.title || "",
      size: data.size || "small",
      icon: data.icon || "",
      lyIcon: data.lyIcon,
      disabled: data.disabled,
      isExpand: data.isExpand,
    };
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    buttonRef.current.addEventListener("onClick", onClick);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <lottus-button ref={buttonRef}></lottus-button>
    </>
  );
});

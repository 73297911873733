export const loginText = {
  images: {
    imgLogo: "images/uteg/logo-login.svg",
    imgLogin: "images/uteg/coverpage.png",
    splashImg: "images/uteg/welcome.png",
  },
  title: "",
  subtitle: "Inicia sesión mediante tu cuenta institucional",
  footerText: "Aviso de privacidad y términos y condiciones",
  mark: "© Lottus 2024",
  splashText: "Te damos la bienvenida a Espacio UTEG",
  errorMsg400: "No pudimos encontrar al usuario indicado",
  errorMsg500: "Ha ocurrido un error, inténtalo más tarde",
  errorMsg403:
    "No encontramos tu cuenta en el sistema, comunícate con soporte técnico en: soporte@ula.edu.mx",
  errorMsg503: "Ha ocurrido un error, inténtalo más tarde",
};
